import { useLocation } from '@reach/router';
import { Slice, navigate } from 'gatsby';
import queryString from 'query-string';
import CountryCodeList from '../../utils/country-codes.json';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { MARKETS_PAGE } from '../../constants/page-links';
import '../../styles/main.scss';
import { Toaster } from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { USER_LOCATION_API, USER_LOCATION_API_KEY } from '../../constants/global';
import { expireTimeOfCountrySelection } from '../../utils/constants';
import { waitForBreadPayments } from '../../utils/breadplacements/initialization';

export const Layout = ({ children }) => {
  const [cookies, setCookie] = useCookies(['countrySelected']);
  const location = useLocation();
  const searchParams = queryString.parse(location.search);
  const { s } = searchParams;
  const findCountryOfUser = async () => {
    try {
      const { data } = await axios.get(
        `${USER_LOCATION_API}?apiKey=${USER_LOCATION_API_KEY}&fields=country_code2,country_name`
      );
      if (data) {
        const { country_code2: country_code } = data;
        if (country_code && country_code.toLowerCase() !== 'us' && country_code.toLowerCase() !== 'ca') {
          navigate(MARKETS_PAGE);
        }
      }
    } catch (error) {
      console.error('Error finding user country:', error?.response);
    }
  };

  const fetchData = async () => {
    try {
      if (s && s == 'us') {
        const countryObject = CountryCodeList.find((country) => country.code.toLowerCase() === s.toLowerCase());
        if (countryObject?.name) {
          setCookie('countrySelected', countryObject.name, { maxAge: expireTimeOfCountrySelection, path: '/' });
        }
        return;
      }
      if (cookies?.countrySelected) {
        const countryName = cookies?.countrySelected.toLowerCase();
        if (countryName && (countryName.toLowerCase() === 'united states' || countryName.toLowerCase() === 'canada')) {
          return;
        }
        const selectedCountry = CountryCodeList.find((country) => country.name.toLowerCase() === countryName);
        if (selectedCountry && selectedCountry.site) {
          window.open(selectedCountry.site, '_self');
        }
      } else {
        await findCountryOfUser();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (process.env.GATSBY_PUBLIC_ENV === 'prod') fetchData();
  }, [cookies]);

  // if (loading) return null;
  const { pageContext } = children?.props || {};
  const { slug } = pageContext || {};

  let removeScrollOnTheHeader = false;
  if (slug && slug.includes('/products')) {
    const product =
      children?.props?.data['allBigCommerceProducts']?.edges.find(
        ({ node }) => node?.id === children.props.pageContext?.id
      )?.node || null;
    const isBedsProductDetailPage = product?.categories?.includes(25);
    removeScrollOnTheHeader = isBedsProductDetailPage;
  }

  const isMarketsPage = location.pathname.includes(MARKETS_PAGE);

  const MainBody = () => <main id="content">{children}</main>;

  if (isMarketsPage) {
    return <MainBody />;
  }

  useEffect(() => {
    // Wait for BreadPayments to be ready before proceeding
    waitForBreadPayments()
      .then(() => {
        console.log('BreadPay installed successfully.');
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Toaster
        position="bottom-right"
        containerStyle={{
          bottom: 30,
          right: 30
        }}
        toastOptions={{
          style: {
            fontSize: 15
          }
        }}
      />
      <Slice alias="header" removeScroll={removeScrollOnTheHeader} />
      <MainBody />
      <Slice alias="footer" />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};
