export const ACCOUNT_MESSAGES_PAGE = '/account/messages/';
export const ACCOUNT_ORDERS_PAGE = '/account/orders/';
export const ACCOUNT_PREFERENCES_PAGE = '/account/preferences/';
export const ACCOUNT_SETTINGS_PAGE = '/account/settings/';
export const ACCOUNT_WISHLISTS_PAGE = '/account/wishlists/';
export const AUTH_PAGE = '/login';
export const CART_PAGE = '/cart';
export const CHECKOUT_PAGE = '/checkout';
export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const FORGOT_PASSWORD = 'forgot-password';
export const MARKETS_PAGE = '/markets';
export const REGISTER_PAGE = '/register';
export const SEARCH_PAGE = '/search';
export const SHOP_PAGE = '/beds';
export const STORES_PAGE = '/stores';
export const TRY_AT_HOTEL_PAGE = '/try-at-hotel';
export const PROFESSIONALS_PAGE = '/professionals';
export const PROFESSIONALS_PRESS_ROOM_PAGE = '/professionals/press-room';
export const WHY_DUX_PAGE = '/why-dux';
export const WHY_DUX_SLEEP_SCIENCE_PAGE = '/why-dux/sleep-science';
export const WHY_DUX_BACK_PAIN_LUMBAR_SUPPORT = '/why-dux/back-pain-relief/lumbar-support';
export const WHY_DUX_INNOVATION_FRAME_MATTRESS = '/why-dux/innovation/interior-frame-mattress';
export const NEWS_AND_EVENTS_PAGE = '/news-and-events';
export const CONTACT_US_PAGE = '/about-us/contact-us';
export const ABOUT_US_PAGE = '/about-us';
export const MAGAZINE_PAGE = '/magazine';
export const FURNITURE_PAGE = '/furniture';
export const BEDDING_PAGE = '/bedding';
export const BEDS_PAGE = '/beds';
export const ACCESSORIES_PAGE = '/accessories';
